import * as React from 'react'
import styled, { css } from 'styled-components';
import Utils from '../../utilities/Utils';

interface IProps {
	name: string
	rounded?: boolean
	width?: number
	backgroundColor?: string
	onClick?: () => void
}

const Container = styled.div`
	position: relative;
	overflow: visible;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid transparent;
	transition: border-color .3s;
	border-radius: 7px;
	overflow: hidden;
	color: white;
	background: #63778b;

	${(props: IProps) => css`
		width: ${props.width}px;
		height: ${props.width}px;
		min-width: ${props.width}px;
	`}

	${(props: IProps) => props.rounded && css`
		border-radius: 50%;
	`}

	${(props: IProps) => props.backgroundColor && css`
		background-color: ${props.backgroundColor};
	`}

	${(props: IProps) => props.onClick && css`
		cursor: pointer;
	`}

	svg {
		width: 100%;
		height: 100%;
		font-weight: bold;
	}
`

export default class Avatar extends React.PureComponent<IProps> {
	static defaultProps = {
		width: 40,
	}

	render() {
		const {
			name,
			rounded,
			width,
			backgroundColor,
			onClick,
		} = this.props


		return (
			<Container
				rounded={rounded}
				width={width}
				backgroundColor={backgroundColor}
				onClick={onClick}
				data-tip={name}
				name={name}
			>
				<svg viewBox="0 0 40 40">
					<text
						x="50%"
						y="50%"
						dy='6px'
						fill="currentColor"
						fontSize="20"
						textAnchor="middle"
					>
						{Utils.getInitials(name).toUpperCase()}
					</text>
				</svg>
			</Container>
		)
	}
}