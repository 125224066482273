import styled from 'styled-components'
import { Style } from '../../../styles'

export default styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 5px;
  background-color: #fff;
  cursor: auto;
  pointer-events: all;
  box-shadow: 0 7px 14px 0 rgba(59,65,94,.1), 0 3px 6px 0 rgba(0,0,0,.07);
  min-width: 600px;
  max-width: 600px;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    min-width: initial;
    max-width: initial;
  }
`