import * as React from 'react'
import { DisplayableError } from '../../types';
import Icon from '../Icons/Icon';

interface IProps {
  title?: string
  errors: Array<DisplayableError>
  onDismiss: (e: React.MouseEvent) => void
}

export default class TooltipError extends React.PureComponent<IProps> {
  static defaultProps = {
    onDismiss: (e) => { }
  }

  getErrors() {
    const { errors } = this.props
    let tooltipErrors = {}

    if (Array.isArray(errors)) {
      errors.forEach(error => {
        if (tooltipErrors[error.field]) {
          tooltipErrors[error.field] = [...tooltipErrors[error.field], error.message]
        } else {
          tooltipErrors[error.field] = [error.message]
        }
      })
    } else {
      tooltipErrors = errors
    }

    return tooltipErrors
  }

  render() {
    const { title, onDismiss } = this.props;

    const errors = this.getErrors()

    return (
      <div className={'popover-container ' + (Object.keys(errors).length > 0 ? 'is-active' : '')} onClick={onDismiss}>
        <div className='tooltip-error'>
          <p className='tooltip-error-message'>{title}</p>
          <ul className='tooltip-error-list'>
            {Object.keys(errors).map((errorKey) => {
              return (
                <li key={errorKey} className='tooltip-error-list-item'>
                  <Icon icon='close' className="tooltip-error-item-icon" />
                  <b>{errorKey.toString()}:</b>

                  <ul>
                    {errors[errorKey].map(errorText => {
                      return (<li key={errorText} dangerouslySetInnerHTML={{ __html: errorText }}></li>);
                    })}

                  </ul>
                </li>
              );
            })}
          </ul>
        </div>
        <svg viewBox="0 0 20 20" className="popover-arrow"><path data-v-41f4d6fe="" d="M0,0 10,10 20,0"></path></svg>
      </div>
    );
  }
}