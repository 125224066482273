import styled from 'styled-components'
import { Style } from '../../../styles'

export default styled.div`
  display: flex;;
  flex: 1;
  flex-direction: column;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    overflow-y: auto;
  }
`