import * as React from 'react'

interface IProps {
  className?: string
}
export class Body extends React.PureComponent<IProps> {
  static defaultProps = {
    className: false
  }

  componentDidMount() {
    const { className } = this.props
    if (className && className.length > 0) document.body.classList.toggle(this.props.className, true)
  }

  componentWillUnmount() {
    const { className } = this.props
    if (className && className.length > 0) document.body.classList.remove(this.props.className)
  }

  render() {
    return null
  }
}