import * as React from 'react'
import Icon, { IconTypes } from '../Icons/Icon'
import styled, { keyframes } from 'styled-components'
import { Style } from '../../styles'

export interface IButtonProps {
	text?: string
	icon?: IconTypes
	isLoading?: boolean
	disabled?: boolean
	type: 'primary' | 'success' | 'warning' | 'danger' | 'default' | 'icon'
	onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
	extraClasses?: string
	style?: object
	tooltip?: string
}

const IconContainer = styled.span`
	margin-right: ${Style.spacing.x0_5};
`

const loading = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
	}
`

const Loader = styled.svg`
  display: flex;
  justify-self: center;
  align-self: center;
  position: relative;
  fill: white;
	animation: ${loading} .5s linear infinite;
	width: 14px;
	height: 14px;
`

export default class Button extends React.PureComponent<IButtonProps> {
	static defaultProps = {
		extraClasses: '',
		style: {},
	}

	renderIcon() {
		const { isLoading, icon } = this.props

		if (isLoading) {
			return (
				<IconContainer>
					<Loader viewBox="0 0 44 44" role="status">
						<path d="M15.542 1.487A21.507 21.507 0 0 0 .5 22c0 11.874 9.626 21.5 21.5 21.5 9.847 0 18.364-6.675 20.809-16.072a1.5 1.5 0 0 0-2.904-.756C37.803 34.755 30.473 40.5 22 40.5 11.783 40.5 3.5 32.217 3.5 22c0-8.137 5.3-15.247 12.942-17.65a1.5 1.5 0 1 0-.9-2.863z"></path>
					</Loader>
				</IconContainer>
			)
		}


		if (icon) {
			return (
				<IconContainer>
					<Icon icon={icon} />
				</IconContainer>
			)
		}

		return null
	}

	render() {
		const { text, type, onClick, extraClasses, style, isLoading, disabled, tooltip } = this.props

		return (
			<a
				href="javascript://"
				className={`button button-${type} ${extraClasses} ${disabled ? 'is-disabled' : ''}`}
				style={style}
				onClick={(isLoading || disabled) ? null : onClick}
				data-tip={tooltip}
				data-place='top'
			>
				{this.renderIcon()}
				{text}
			</a>
		)
	}
}