import * as React from 'react'
import Icon from '../../Icons/Icon';

interface IProps {
	title: string
	navigation?: JSX.Element
	onCloseClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
}

export default class ModalHeader extends React.PureComponent<IProps> {
	render () {
		const { title, navigation, onCloseClick } = this.props

		return (
			<div className={`modal-head ${navigation ? 'has-navigation' : ''}`}>
				<div className='modal-header'>
					<h3 className='modal-header-title'>
						<span className='modal-header-title-text'>
							{title}
						</span>
					</h3>

					{onCloseClick && <a href='javascript://' className='modal-close' onClick={onCloseClick}>
						<Icon icon='close' className='modal-close-icon' />
					</a>}
				</div>

				{navigation}
			</div>
		)
	}
}