import * as React from 'react'
import ModalHeader from './Parts/ModalHeader'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import ModalContent from './Parts/ModalContent'
import styled from 'styled-components'
import { Style } from '../../styles'
import { useTranslation } from 'react-i18next'
import Modal from './Modal'
import SignaturePad from 'signature_pad'
import TooltipError from '../Tooltips/ErrorTooltip'
import Button from '../Button/Button'
import { DisplayableError, SignatureResult, SignatureType } from '../../types'


const SignatureModalWindow = styled(ModalWindow)`
	max-width: 500px;

	@media screen and (max-width: ${Style.breakpoints.SMALL}){
		max-width: initial;
	}
`

const CanvasContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Style.color.border};
  border-radius: 4px;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: radial-gradient(rgba(53,48,150,.08) 1.5px,transparent 0);
    background-size: 10px 10px;
    content: "";
    pointer-events: none;
  }
`

const Canvas = styled.canvas`
  width: 100%;
  min-height: 192px;
`

type IProps = {
  show: boolean
  onSubmit: (signatureResult: SignatureResult) => void
  onClose: () => void
}

const SignatureModal = (props: IProps) => {
  const { show, onClose } = props
  const { t } = useTranslation()
  const [signaturePad, setSignaturePad] = React.useState<SignaturePad>(null)
  const [name, setName] = React.useState('')
  const [signatureEmpty, setSignatureEmpty] = React.useState(true)
  const [isSigning, setIsSigning] = React.useState(false)
  const [errors, setErrors] = React.useState<DisplayableError[]>([])
  const canvas = React.createRef<HTMLCanvasElement>()

  // Add resize event listener needed for the canvas
  React.useEffect(() => {
    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  })

  React.useEffect(() => {
    if (show) {
      if (canvas.current) {
        const signaturePadInstance = new SignaturePad(canvas.current);

        signaturePadInstance.addEventListener('endStroke', onCanvasEndStroke)
        setSignaturePad(signaturePadInstance)
        onResize()
      }
    }

    return () => {
      if (show) {
        setName('')
        setSignatureEmpty(true)
        setIsSigning(false)

        if (signaturePad) {
          signaturePad.clear()
          signaturePad.off()
          setSignaturePad(null)
        }
      }
    };
  }, [show])

  const onResize = () => {
    const ratio = Math.max(window.devicePixelRatio || 1, 1);

    if (canvas.current) {
      canvas.current.width = canvas.current.offsetWidth * ratio;
      canvas.current.height = canvas.current.offsetHeight * ratio;
      canvas.current.getContext("2d").scale(ratio, ratio);
      if (signaturePad) signaturePad.clear();
    }
  }

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }

  const onCanvasEndStroke = () => {
    setSignatureEmpty(false)
  }

  const onCanvasClearClick = () => {
    if (signaturePad) {
      signaturePad.clear()
      setSignatureEmpty(true)
    }
  }

  const onErrorsDismiss = () => {
    setErrors([])
  }

  const getCroppedSignatureData = () => {
    var croppedCanvas = document.createElement('canvas'),
      croppedCtx = croppedCanvas.getContext('2d');

    croppedCanvas.width = canvas.current.width;
    croppedCanvas.height = canvas.current.height;
    croppedCtx.drawImage(canvas.current, 0, 0);

    var w = croppedCanvas.width,
      h = croppedCanvas.height,
      pix = { x: [], y: [] },
      imageData = croppedCtx.getImageData(0, 0, croppedCanvas.width, croppedCanvas.height),
      x, y, index;

    for (y = 0; y < h; y++) {
      for (x = 0; x < w; x++) {
        index = (y * w + x) * 4;
        if (imageData.data[index + 3] > 0) {
          pix.x.push(x);
          pix.y.push(y);

        }
      }
    }
    pix.x.sort(function (a, b) { return a - b });
    pix.y.sort(function (a, b) { return a - b });
    var n = pix.x.length - 1;

    w = pix.x[n] - pix.x[0];
    h = pix.y[n] - pix.y[0];
    var cut = croppedCtx.getImageData(pix.x[0], pix.y[0], w, h);

    croppedCanvas.width = w;
    croppedCanvas.height = h;
    croppedCtx.putImageData(cut, 0, 0);

    return croppedCanvas.toDataURL();
  }

  const onFormSubmit = async (e) => {
    e.preventDefault()

    const signature = getCroppedSignatureData()

    setIsSigning(true)
    props.onSubmit({ type: SignatureType.DRAW, full_name: name, base64: signature })
    if (onClose) onClose()
  }

  return (
    <Modal show={show} onClose={onClose}>
      <SignatureModalWindow>
        <ModalHeader title={t('SignatureModal::Accept & Sign')} onCloseClick={close} />
        <ModalMiddle>
          <form onSubmit={onFormSubmit}>
            <ModalContent>
              <div className='form-item'>
                <label>{t('SignatureModal::Full name')}<span>*</span></label>
                <input
                  type='text'
                  placeholder={t('SignatureModal::Full name')}
                  onChange={onNameChange}
                  value={name}
                />
              </div>

              <div className='form-item'>
                <label>{t('SignatureModal::Signature')}<span>*</span></label>
                <CanvasContainer>
                  <Canvas ref={canvas} />
                </CanvasContainer>
              </div>

              {!signatureEmpty && <div className='form-item'>
                <Button
                  type='default'
                  text={t('SignatureModal::Clear')}
                  onClick={onCanvasClearClick}
                />
              </div>}
            </ModalContent>

            <div className='modal-footer'>
              <div />
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={onErrorsDismiss}
                />
                <Button
                  text={t('SignatureModal::Confirm signature')}
                  type='success'
                  isLoading={isSigning}
                  onClick={onFormSubmit}
                  disabled={name.length === 0 || signatureEmpty}
                />
              </div>
            </div>
          </form>
        </ModalMiddle>
      </SignatureModalWindow>
    </Modal>
  )
}

export default SignatureModal