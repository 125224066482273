import * as React from 'react';
import styled, { css } from 'styled-components'
import { Style } from '../../styles'

const IconButton = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	color: #5a6f90;
	border: 1px solid ${Style.color.border};
	background-color: #f4f8fc;
	box-shadow: none;
	padding: 5px 12px;
	min-height: 36px;

	svg {
		width: 18px;
		height: 18px;
	}

	i {
		font-size: 18px;
	}

	&:first-child {
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
	}

	&:last-child {
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
		border-left: none;
	}

	&:only-child {
		border-left: 1px solid ${Style.color.border};
	}

	${props => props.onClick && css`
		cursor: pointer;
	`}
`

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`

interface IProps {
	buttons: {
		icon: JSX.Element,
		tooltip: string,
		onClick?: () => void
	}[]
}


const IconButtonGroup = (props: IProps) => {
	const { buttons } = props;

	return (
		<Container>
			{buttons.map(({ icon, tooltip, onClick }, index) => {
				return (
					<IconButton key={index} data-tip={tooltip} onClick={onClick}>
						{icon}
					</IconButton>
				)
			})}
		</Container>
	)
}

export default IconButtonGroup;