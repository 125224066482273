import * as React from 'react'
import styled from 'styled-components'
import Avatar from '../Avatar/Avatar'
import Icon from '../Icons/Icon'
import { Style } from '../../styles'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100vh;
	overflow: hidden;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		flex-direction: column;
	}
`

const Sidebar = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: #181818;
	width: 80px;
	padding: ${Style.spacing.x1_5} ${Style.spacing.x2};

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		flex-direction: row;
		align-items: center;
		width: 100%;
	}
`

const Company = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		max-width: 82vw;
	}
`

const CompanyName = styled.div`
	display: none;
	width: 100%;
	font-size: 18px;
	margin: 0 ${Style.spacing.x1_5};
	color: white;
	font-weight: bold;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		display: block;
	}
`

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 100%;
`

const Navigation = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	background: #fff;
	border-bottom: 1px solid ${Style.color.border};
	min-height: 60px;
	padding: 0 ${Style.spacing.x2};
`

const Title = styled.div`
	font-size: 18px;
	font-weight: bold;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`

const Actions = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	> * {
		margin: 0 ${Style.spacing.x0_5};

		&:last-child {
			margin-right: 0;
		}
	}
`

const Content = styled.div`
	width: 100%;
	height: 100%;

	margin: 0 auto;
	overflow-x: hidden;
	overflow-y: auto;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		height: calc(100vh - 135px);
	}
`

const CloseContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 50px;
	height: 50px;
	cursor: pointer;

	svg {
		width: 20px;
		height: 20px;
		fill: white;
	}

	i {
		font-size: 20px;
		color: white;

		@media screen and (max-width: ${Style.breakpoints.SMALL}) {
			font-size: 25px;
		}
	}

	> *:first-child {
		@media screen and (max-width: ${Style.breakpoints.SMALL}) {
			display: none;
		}
	}

	> *:last-child {
		display: none;

		@media screen and (max-width: ${Style.breakpoints.SMALL}) {
			display: flex;
		}
	}
`

interface IProps {
	companyName: string
	title: string
	actions?: React.ReactNode[]
	children?: React.ReactNode
}

const ContactPortal = (props: IProps) => {
	const { companyName, title, actions, children } = props
	const { t } = useTranslation()

	return (
		<Container>
			<Sidebar>
				<Company>
					<Avatar
						name={companyName || 'Bizzey.com'}
						width={50}
					/>
					<CompanyName>
						{companyName}
					</CompanyName>
				</Company>

				<CloseContainer onClick={() => window.close()} data-tip={t('ContactPortal::Close')}>
					<Icon icon='arrow-left' />
					<Icon icon='close' />
				</CloseContainer>
			</Sidebar>

			<Wrapper>
				<Navigation>
					<Title>
						{title}
					</Title>

					<Actions>
						{actions?.length > 0 && actions.map(action => action)}
					</Actions>
				</Navigation>
				<Content>
					{children}
				</Content>
			</Wrapper>
		</Container>
	)
}

export default ContactPortal