import * as React from 'react'
import { CSSTransition } from 'react-transition-group'
import { Body } from '../Body/Body';
import ModalSidebar from './ModalSidebar';
import ModalSidebarWrapper from './ModalSidebarWrapper';

export interface IModalProps {
	show: boolean
	mode?: 'window' | 'sidebar'
	isLoading?: boolean
	onClose: () => void
	backgroundClassNames?: string
	containerClassNames?: string
}

export default class Modal extends React.PureComponent<IModalProps> {
	constructor(props: IModalProps) {
		super(props);

		this.onKeydown = this.onKeydown.bind(this)
	}

	componentDidMount() {
		document.addEventListener('keydown', this.onKeydown)
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.onKeydown)
	}

	onKeydown(e) {
		const { show } = this.props

		if (show) {
			if ("key" in e && (e.key === "Escape" || e.key === "Esc")) {
				this.props.onClose()
			} else if (e.keyCode === 27) {
				this.props.onClose()
			}
		}
	}

	render() {
		const {
			show,
			mode,
			onClose,
			backgroundClassNames,
			containerClassNames,
			children,
		} = this.props;


		let wrappedChildren = <>
			{children}
		</>

		if (mode === 'sidebar') {
			wrappedChildren = <ModalSidebar>
				<ModalSidebarWrapper>
					{children}
				</ModalSidebarWrapper>
			</ModalSidebar>
		}


		return (
			<CSSTransition
				in={show}
				classNames={{
					enter: 'is-entering',
					enterActive: 'is-entering-active',
					exit: 'is-leaving',
					exitActive: 'is-leaving',
				}}
				timeout={250}
				unmountOnExit
			>
				<div className='modal'>
					<Body className='no-overflow' />

					<div className='modal-overflow'>
						<div className={`modal-background ${backgroundClassNames}`} onClick={onClose} />
						<div className={`modal-container ${mode} ${containerClassNames}`}>
							{wrappedChildren}
						</div>
					</div>
				</div>
			</CSSTransition >
		);
	}
}