import styled from 'styled-components'
import { Style } from '../../styles'

export default styled.div`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  width: 100%;
	background: white;
	min-width: 200px;
	min-height: 100vh;
	max-width: 820px;
	pointer-events: auto;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		max-width: initial;
	}
`