import * as React from 'react'

interface IProps {
	showOnMount?: boolean
}

interface IState {
	show: boolean
	animationClass: string
}

export default class AwardSuccess extends React.Component<IProps, IState> {
	static defaultProps = {
		showOnMount: false
	}

	constructor(props: IProps) {
		super(props)

		this.state = {
			show: false,
			animationClass: 'animate-in'
		}

		this.show = this.show.bind(this)
		this.hide = this.hide.bind(this)
	}

	componentDidMount () {
		const { showOnMount  } = this.props

		if (showOnMount) {
			this.show()
		}
	}

	show() {
		this.setState({
			show: true,
			animationClass: 'animate-in'
		}, () => {
			setTimeout(() => {
				this.hide()
			}, 3000)
		})
	}

	hide() {
		this.setState({
			animationClass: 'animate-out',
		}, () => {
			setTimeout(() => {
				this.setState({
					show: false
				})
			}, 2000)
		})
	}

	render () {
		const { show, animationClass } = this.state

		if (!show) return null

		return (
			<div className={`award-container ${animationClass}`} onClick={this.hide}>
				<div className='award-wrapper'>
					<div className='award'>
						<svg fill='#4bc800' width='100%' height='100%' viewBox='0 0 24 24'>
							<path d='M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M11,16.5L18,9.5L16.59,8.09L11,13.67L7.91,10.59L6.5,12L11,16.5Z'></path>
						</svg>
					</div>

					<div className='award-confetti'></div>
					<div className='award-confetti two'></div>
					<div className='award-confetti three'></div>
					<div className='award-confetti four'></div>
					<div className='award-confetti--purple'></div>
					<div className='award-confetti--purple two'></div>
					<div className='award-confetti--purple three'></div>
					<div className='award-confetti--purple four'></div>
				</div>
			</div>
		)
	}
}