import * as React from 'react'
import styled, { keyframes, css } from 'styled-components'
import { Style } from '../../styles'

interface IProps {
  color?: string
  size?: 'small' | 'medium' | 'large'
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const move = keyframes`
  to {
    opacity: 1;
  }
`

const Container = styled.span<IProps>`
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 20px;
  height: 20px;
  animation: ${rotate} 1.2s infinite linear;

  ${(props: IProps) => props.size === 'small' && css`
    font-size: 14px;
    width: 14px;
    height: 14px;

    ${Dot} {
      width: 6px;
      height: 6px;
    }
  `}

  ${(props: IProps) => props.size === 'medium' && css`
    font-size: 20px;
    width: 20px;
    height: 20px;

    ${Dot} {
      width: 9px;
      height: 9px;
    }
  `}

  ${(props: IProps) => props.size === 'large' && css`
    width: 32px;
    height: 32px;

    ${Dot} {
      width: 14px;
      height: 14px;
    }
  `}

  ${(props: IProps) => props.color && css`
    ${Dot} {
      background-color: ${props.color};
    }
  `}
`

const Dot = styled.div`
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: ${Style.color.brandPrimary};
  border-radius: 100%;
  transform: scale(.75);
  transform-origin: 50% 50%;
  opacity: .3;
  animation: ${move} 1s infinite linear alternate;

  &:nth-child(1) {
    top: 0;
    left: 0;
  }

  &:nth-child(2) {
    top: 0;
    right: 0;
    animation-delay: .4s;
  }

  &:nth-child(3) {
    right: 0;
    bottom: 0;
    animation-delay: .8s;
  }

  &:nth-child(4) {
    bottom: 0;
    left: 0;
    animation-delay: 1.2s;
  }
`

export default (props: IProps) => {
	return (
    <Container
      size={props.size || 'medium'}
      color={props.color || Style.color.brandPrimary}
    >
      <Dot />
      <Dot />
      <Dot />
      <Dot />
    </Container>
	)
}