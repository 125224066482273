import styled from 'styled-components'
import { Style } from '../../../styles'

export default styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
      max-width: initial
  }

  form {
    width: 100%;
  }
`