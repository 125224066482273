import * as React from 'react'
import styled, { css } from 'styled-components'
import { Style } from '../../styles'

export type BadgeType = 'primary' | 'success' | 'warning' | 'danger' | 'default' | 'grey'

interface IProps {
	text?: string
	type: BadgeType
}

const Container = styled.span<IProps>`
	padding: 0px 6px;
	border-radius: 8px;
	vertical-align: middle;
	color: #fff;
	font-size: 12px;
	line-height: 16px;
	font-weight: 500;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border: 1px solid $base-border-color;
	transition: all $base-transition-speed linear;
	white-space: nowrap;

	${props => props.type === 'primary' && css`
		color: #3d4eac;
		background: #d6ecff;
		border: 1px solid #3d4eac;
	`}

	${props => props.type === 'warning' && css`
		color: #8A2C0D;
		background: #FEEBDC;
		border: 1px solid #8A2C0D;
	`}

	${props => props.type === 'danger' && css`
		color: #a41c4e;
		background: #fde2dd;
		border: 1px solid #a41c4e;
	`}

	${props => props.type === 'success' && css`
		color: #0e6245;
		background: #cbf4c9;
		border: 1px solid #0e6245;
	`}

	${props => props.type === 'default' && css`
		color: $white;
		background: #244063;
		border: 1px solid white;
	`}

	${props => props.type === 'grey' && css`
		color: black;
		background: #f7f7fa;
		border: 1px solid ${Style.color.border};
	`}
`

export default class Badge extends React.PureComponent<IProps> {
	render() {
		const { text, type, children } = this.props

		return (
			<Container type={type}>
				{text ? text : children}
			</Container>
		)
	}

}